.react-modal-modal {
  @apply absolute top-0 left-0 right-0 bottom-0 h-full bg-gray-100;
}

@media screen and (min-width: 768px) {
  .react-modal-modal {
    @apply left-auto;
    width: 432px;
  }
}

.react-modal-overlay {
  @apply fixed top-0 left-0 right-0 bottom-0;
  @apply bg-black bg-opacity-10;
}
